
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { drawPolygon } from '@/utils/formatData'
require('echarts-liquidfill')

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  tableData = []
  deviceStatus = ''

  private onlineImg = require('@/assets/icon/greenhouse/normal.png')
  private onlineImgCursor = require('@/assets/icon/greenhouse/normal-big.png')
  private offImg = require('@/assets/icon/greenhouse/offline.png')
  private offImgCursor = require('@/assets/icon/greenhouse/offline-big.png')

  private deviceList: any = []
  private drawerData: any = {
    latestReportData: {
      auto: ''
    }
  }

  private deviceListShow = true

  get projectId() {
    return this.$route.params.projectId as string
  }

  $refs!: {
    statusEcharts: any;
  }

  created() {
    this.getProjectLocation()
  }

  destroyed(): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation() {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  insertControlCabinet(row: any, command: any) {
    console.log('row', row)
    this.$axios.post(this.$apis.greenhouse.insertControlCabinet, {
      deviceId: row.deviceId, // 设备ID
      commandSource: '1', // 指令来源 1:手动 2:定时计划 3:智能计划 4:喷灌互动 5:系统自动
      commandTarget: row.moduleField, // 控制柜设备子模块标识, 参考字典 controlCabinetModule
      commandPos: row.moduleNo, // 控制柜设备子模块位置，排号
      operateCommand: command // 操作指令 0关闭 1开启 2停止
    }).then(() => {
      this.$message.success('操作成功')
      this.loadDetail(row.deviceId)
    })
  }

  // 获取设备信息
  getDeviceList() {
    this.$axios.get(this.$apis.greenhouse.selectControlCabinet, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      const data = [{
        name: '在线',
        value: res.onlineAmount
      }, {
        name: '离线',
        value: res.offLineAmount
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  // 在线离线图
  drawEchart(data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs.statusEcharts)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap(projectInfo: { longitude: number; latitude: number }, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon(deviceStatus: string, isCurrent = false) {
    if (!isCurrent) {
      return deviceStatus === '1' ? this.onlineImg : this.offImg
    } else {
      return deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
    }
  }

  // 地图打点
  drawMarker(list: any) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: any) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
            extData: item
          })
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen(e: any) {
    this.drawer = true
    const data = e.target.getExtData()
    console.log('datadata', data)
    if (data.deviceId) {
      // this.drawerData = data
      // 点击切换大图标
      const iconClick = this.getIcon(data.deviceStatus, true)
      if (data.longitude && data.latitude) {
        e.target.setOffset(new AMap.Pixel(-22, -64))
        e.target.setIcon(iconClick)
      }
      this.loadDetail(data.deviceId)
    }
  }

  getStatus(num: string) {
    const arr = ['正常', '偏高', '偏低']
    return num ? arr[Number(num)] : '--'
  }

  loadDetail(deviceId: any) {
    if (deviceId) {
      this.$axios.get(this.$apis.greenhouse.selectControlCabinetById, {
        deviceId
      }).then(res => {
        this.drawerData = res
        this.tableData = res.configs
        this.deviceStatus = res.deviceStatus
        console.log(this.tableData)
        console.log(res)
      })
    }
  }

  // 抽屉关闭
  drawerClose() {
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = {
        latestReportData: {}
      }
    }
  }

  // 实时监测点击
  leftDrawerClick() {
    this.deviceListShow = !this.deviceListShow
  }

  // 列表被点击
  tableClick(row: any) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude && row.groupFlag === '0') {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceId === row.deviceId
        })
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 替换图标
  iconTab() {
    // 将图标替换
    console.log('drawerData', this.drawerData)
    if (this.map && this.drawerData.deviceId) {
      const deviceId = this.drawerData.deviceId || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.getExtData().deviceId === deviceId)
      const icon = this.getIcon(this.drawerData.deviceStatus)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      marker.setOffset(new AMap.Pixel(-18, -48))
      marker.setIcon(icon)
    }
  }
}
